import React from 'react'
import styled, { css } from 'styled-components'
import { object, string } from 'prop-types'
import { stripUnit } from 'polished'

import { media } from 'styles/media'
import * as spacing from 'styles/spacing'
import { square } from 'styles/helpers'
import { primaryColor } from 'styles/colors'

import Heading from 'components/Heading'

const Container = styled.div`
  position: static;
`

const Inner = styled.ul`
  list-style: none;
  padding: 0;

  ${media.small`
    ${props =>
      props.twoCol &&
      css`
        column-count: 2;
        column-gap: ${spacing.xLarge};
      `}
  `};
`

const Item = styled.li`
  position: relative;
  padding-left: ${stripUnit(spacing.medium) * 1.25 + 'px'};

  & + & {
    margin-top: ${spacing.small};
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 10px;
    ${square('6px')};
    background-color: ${primaryColor};

    ${media.medium`
      top: 11px;
    `};
  }
`

const StyledHeading = styled(Heading)`
  margin-bottom: ${stripUnit(spacing.small) * 1.5 + 'px'};

  ${media.medium`
    margin-bottom: ${spacing.medium};
  `};

  ${media.large`
    margin-bottom: ${spacing.large};
  `};
`

const List = ({ heading, items, twoCol }) => (
  <Container>
    {heading && <StyledHeading size={6}>{heading}</StyledHeading>}
    <Inner twoCol={twoCol}>
      {items.map((item, i) => (
        <Item key={i}>{item}</Item>
      ))}
    </Inner>
  </Container>
)

List.propTypes = {
  items: object.isRequired,
  heading: string,
}

export default List
