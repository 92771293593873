import React from 'react'
import styled from 'styled-components'
import { string, node } from 'prop-types'
import { rgba, stripUnit } from 'polished'
import Img from 'gatsby-image/withIEPolyfill'

import { widowFix } from 'utils/string'
import { navHeightMobile, navHeight } from 'styles/variables'
import { white, black } from 'styles/colors'
import { media } from 'styles/media'
import { cover } from 'styles/helpers'
import * as spacing from 'styles/spacing'

import Wrapper from 'components/Wrapper'
import Nav from 'components/Nav'
import SmallCaps from 'components/SmallCaps'
import Heading from 'components/Heading'

const Container = styled.div`
  position: relative;
  background-color: #222;
  padding-top: ${navHeightMobile};

  &:after {
    content: '';
    ${cover('absolute')};
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.5) 0%,
        rgba(0, 0, 0, 0) 100%
      ),
      linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
  }

  ${media.medium`
    padding-top: ${navHeight};
  `};
`

const Inner = styled.div`
  position: static;
  padding-top: 2rem;
  padding-bottom: 4rem;

  ${media.small`
    padding-top: 3rem;
    padding-bottom: 6rem;
  `};

  ${media.medium`
    padding-top: 4rem;
    padding-bottom: 8rem;
  `};

  ${media.large`
    padding-top: 6rem;
    padding-bottom: 10rem;
  `};

  ${media.xLarge`
    padding-top: 7rem;
    padding-bottom: 12rem;
  `};
`

const Content = styled.div`
  position: relative;
  z-index: 10;
  max-width: 560px;

  ${media.medium`
    max-width: 640px;
  `};

  ${media.large`
    max-width: 700px;
  `};
`

const Title = styled(Heading)`
  color: ${white};
  text-shadow: 0px 2px 4px ${rgba(black, 0.25)};
`

const Eyebrow = styled(SmallCaps)`
  color: ${rgba(white, 0.8)};
  display: block;
  margin-bottom: ${spacing.small};
  text-shadow: 0px 2px 4px ${rgba(black, 0.25)};

  ${media.medium`
    margin-bottom: ${stripUnit(spacing.small) * 1.5 + 'px'};
  `};

  ${media.large`
    margin-bottom: ${spacing.medium};
  `};
`

const BackgroundWrapper = styled.div`
  ${cover('absolute')};

  overflow: hidden;

  background-color: ${({ $backgroundColor }) => $backgroundColor};

  > * {
    max-height: 100%;
    height: 100%;
  }
`

const Header = ({
  eyebrow,
  heading,
  children,
  fluid,
  pageAlternativeLangPath,
  objectFit = 'cover',
  backgroundColor = 'black',
}) => (
  <Container>
    <Nav pageAlternativeLangPath={pageAlternativeLangPath} />
    <Inner>
      <Wrapper size='narrow'>
        <Content>
          {eyebrow && <Eyebrow>{eyebrow}</Eyebrow>}
          <Title size={1}>{widowFix(heading)}</Title>
          {children && <div>{children}</div>}
        </Content>
      </Wrapper>
    </Inner>

    <BackgroundWrapper $backgroundColor={backgroundColor}>
      <Img
        fluid={fluid}
        role='presentation'
        objectFit={objectFit}
        loading='eager'
        backgroundColor={backgroundColor}
        fadeIn={false}
      />
    </BackgroundWrapper>
  </Container>
)

Header.propTypes = {
  eyebrow: string,
  heading: string.isRequired,
  children: node,
}

export default Header
