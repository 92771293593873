import React from 'react'
import styled, { css } from 'styled-components'
import { array } from 'prop-types'
import GatsbyImage from 'gatsby-image'

import { media } from 'styles/media'
import { cover } from 'styles/helpers'
import { borderColor } from 'styles/colors'
import * as spacing from 'styles/spacing'

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: ${spacing.medium};

  ${media.small`
    grid-template-columns: repeat(2, 1fr);
  `};

  ${media.medium`
    grid-gap: ${spacing.large};
  `};

  ${media.large`
    grid-gap: ${spacing.xLarge};
  `};
`

const Item = styled.div`
  position: relative;
  background-color: ${borderColor};

  &:after {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 100%;
  }

  /* &:last-child {

  } */

  ${({ $wide }) =>
    $wide &&
    css`
      grid-column: span 2;

      &:after {
        padding-bottom: 50%;

        ${media.medium`
    padding-bottom: 40%; /* Smaller than 50% */
  `};
      }
    `}
`

const Photo = styled(GatsbyImage)`
  ${cover('absolute')};
  position: absolute !important;
`

const PhotoGallery = ({ photos }) => (
  <Container>
    {photos.map((photo, i, arr) => {
      const isWide = i + 1 >= arr.length && arr.length % 2 !== 0

      let fluid = photo

      if (photo.wide || photo.square) {
        fluid = isWide
          ? photo.wide.childImageSharp.fluid
          : photo.square.childImageSharp.fluid
      }

      return (
        <Item key={i} $wide={isWide}>
          <Photo fluid={fluid} />
        </Item>
      )
    })}
  </Container>
)

PhotoGallery.propTypes = {
  photos: array.isRequired,
}

export default PhotoGallery
