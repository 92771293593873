import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'

import Layout from 'components/layout'
import MetaTags from 'components/MetaTags'
import Section, { SectionBlock } from 'components/Section'
import Wrapper from 'components/Wrapper'
import Header from 'components/Header'
import Heading from 'components/Heading'
import List from 'components/List'
import PhotoGallery from 'components/PhotoGallery'
import GridRow from 'components/grid/GridRow'
import GridItem from 'components/grid/GridItem'
import { graphql } from 'gatsby'

const ExploreMontreal = ({ data }) => {
  const cmsData = data.prismic.allNeighbourhood_pages.edges[0].node
  const {
    headerImage: {
      childImageSharp: { fluid },
    },
  } = data
  const intl = useIntl()

  return (
    <Layout>
      <MetaTags
        title={`${intl.formatMessage({
          id: 'footer.exploreMontreal',
        })} | Hotel Griffintown`}
      />
      <Header
        eyebrow={intl.formatMessage({ id: 'footer.exploreMontreal' })}
        heading={cmsData.title[0].text}
        fluid={fluid}
      />
      <Section>
        <Wrapper size='narrow'>
          <SectionBlock>
            <GridRow>
              <GridItem xs='1-1' lg='3-4'>
                <Heading size={4} variant='sans'>
                  {cmsData.subheading[0].text}
                </Heading>
                <p>{cmsData.content[0].text}</p>
              </GridItem>
            </GridRow>
          </SectionBlock>
          <SectionBlock>
            <List
              heading='Attractions'
              items={cmsData.attractions.map(a => a.text[0].text)}
              twoCol
            />
          </SectionBlock>
          <SectionBlock>
            <List
              heading='Restaurant & Bars'
              items={cmsData.restaurants_and_bars.map(a => a.text[0].text)}
              twoCol
            />
          </SectionBlock>
        </Wrapper>
      </Section>
      <Section flushTop>
        <Wrapper>
          <PhotoGallery
            photos={[
              { src: require('../assets/images/neighbourhood/five-roses.jpg') },
              { src: require('../assets/images/neighbourhood/restaurant.jpg') },
              { src: require('../assets/images/neighbourhood/bike.jpg') },
              { src: require('../assets/images/neighbourhood/group.jpg') },
              { src: require('../assets/images/neighbourhood/coffee.jpg') },
            ]}
          />
        </Wrapper>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query ExploreMontrealPage($language: String!) {
    headerImage: file(name: { eq: "img-hero-neighbourhood" }) {
      childImageSharp {
        fluid(maxWidth: 2560, quality: 100, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    prismic {
      allNeighbourhood_pages(lang: $language) {
        edges {
          node {
            subheading
            title
            content
            attractions {
              text
            }
            restaurants_and_bars {
              text
            }
          }
        }
      }
    }
  }
`

export default ExploreMontreal
