import React from 'react'
import { node } from 'prop-types'

import { GlobalStyle } from 'styles/global'
import Footer from 'components/Footer'
// import BookingBar from 'components/BookingBar'
// import COVIDModal from 'components/Modal/COVIDModal'

const Layout = ({ children }) => {
  return (
    <>
      <GlobalStyle />
      {children}
      {/* <Instagram /> */}
      {/* <BookingBar /> */}

      <Footer />

      {/* <COVIDModal /> */}
    </>
  )
}

Layout.propTypes = {
  children: node.isRequired,
}

export default Layout
